import React from "react";
import "../custom.scss"
import { Nav, Navbar, Table, NavDropdown } from "react-bootstrap"
import Dropdown from 'react-bootstrap/Dropdown';

import doc1 from "../documents/Anhållan-om-ledighet-rev-24.pdf"
import doc2 from "../documents/Läsårstider-hem-2022-2023.pdf"
import doc3 from "../documents/skolval-2023.pdf"
import doc4 from "../documents/Läsårstider hem 2025-2026.pdf"
import doc5 from "../documents/Läsårstider-hem-2024-2025.pdf"
import pdfIcon from "../images/pdf-icon.png"


function FileDropDown() {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="warning" id="dropdown-basic">
                Blanketter
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href={doc1}>Ledighet</Dropdown.Item>
                <Dropdown.Item href={doc5}>Läsårstider 24/25</Dropdown.Item>
                <Dropdown.Item href={doc4}>Läsårstider 25/26</Dropdown.Item>
                {/* <Dropdown.Item href={doc3}>Skolval 2023</Dropdown.Item> */}
            </Dropdown.Menu>
        </Dropdown>

    );
}

export default FileDropDown;