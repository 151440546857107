import React from "react";
import "../custom.scss"
import background from "../images/angryimg.png"
import { Carousel, Card, Button, Container, Row, Col, CardGroup, Table } from "react-bootstrap"
import testImg from "../images/musikladan_Caru.jpeg"
import skolval from "../documents/skolval-2022 (1).pdf"
import pdfIcon from "../images/pdf-icon.png"
import doc3 from "../documents/skolval-2023.pdf"

import FileDropDown from "./fileDropDown";


const Feed = React.memo((props) => {
    React.useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=your-app-id&autoLogAppEvents=1";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <React.Fragment>
            <div id="fb-root" ></div>
            <div
                className="backgroundd"
                class="fb-page"
                data-href="https://www.facebook.com/musikladan/"
                data-tabs="timeline"
                data-width=""
                data-height=""
                data-small-header="false"
                data-adapt-container-width="true"
                data-hide-cover="false"
                data-show-facepile="true"
            >
                <blockquote
                    cite="https://www.facebook.com/musikladan/"
                    class="fb-xfbml-parse-ignore"
                >
                    <a href="https://www.facebook.com/musikladan/">Your Page</a>
                </blockquote>
            </div>
        </React.Fragment>
    );
});

const styles = {

    cardImage: {
        objectFit: 'cover',

        width: '100%',
        height: '30vh'

    },

    cardColor: {
        backgroundColor: "rgba(245, 245, 245, 0.4)"

    },

    cardBody1: {
        width: '100%',
        justifyContent: 'stretch',
        display: 'flex-grow',
        flexDirection: 'row'

    },

    cardBody2: {
        maxWidth: '25%',
        justifyContent: 'flex-grow'

    },

    faceBook: {
        justifyContent: 'right',
        marginLeft: '0',
        marginRight: '1'
    }
}


function Home() {
    return (

        <div className="font-face-gm">
            <div>
                <Card className="bg-dark text-white">
                    <Card.Img src={testImg} style={styles.cardImage} alt="Card image" />
                    <Card src={doc3}/>

                </Card>
                <FileDropDown></FileDropDown>

            </div>
            
            <CardGroup>
                        <Card className="text-left" style={styles.cardColor}>

                            <Card.Body style={styles.cardBody1}>
                                <Card.Body style={styles.cardBody1}></Card.Body>
                                <Card.Title className="text-center" style={styles.cardBody1}><h1>Välkommen till Musiklådan</h1></Card.Title>
                                <Card.Title className="text-center" style={styles.cardBody1}><h3></h3></Card.Title>
                                <Card.Body style={styles.cardBody1}>
                                    <div>
                                    
                                    </div>

                                    

                                    <p>
                                    </p>


                                    Musiklådan är en icke-kommunal skola i Gottsunda med ca 140 elever. Skolan drivs sedan 1994 av en föräldraförening i nära samarbete med personalen. Musiklådan är en liten skola där all personal känner alla barn, en bra förutsättning för en trygg skola. Skolan har en klass i varje årskurs från förskoleklass till årskurs 6. På Musiklådan har vi rörelsepass på schemat varje dag. I lågstadiet får eleverna musik i halvklass varje vecka, förutom den ordinarie musiklektionen. Eleverna i mellanstadiet ges möjlighet att prova på olika instrument.
                                    <p></p>

                                    <h2>Föräldraförening</h2>
                                    Skolan är föräldradriven och har man barn på skolan kan man bli medlem i föräldraföreningen. Medlemsavgiften på 100 kr går till uteleksaker (Bg 5918- 9969). Har du frågor om föreningen, tag kontakt med ordförande Ljiljana Gubeljic, styrelsen@musikladan.se


                                    <p></p>

                                    <h2>IBIS-skola med rörelsesatsning</h2>
                                    
                                    Musiklådan är sedan sex år en IBIS-skola, med mål att skapa positiva lärmiljöer. IBIS står för inkluderande beteendestöd i skolan och är ett samarbete med Uppsala universitet. En pågående rörelsesatsning görs som ett led i skolans hälsobefrämjande arbete. Målet är att rörelse ska bli en naturlig del av lärandet och lärmiljön med hjälp av rörelseaktiviteter där rörelseglädje ger ökad grundmotorisk förmåga, bättre mående och skolresultat.


<p></p>
                                </Card.Body>

                            </Card.Body>


                        </Card>
                        <Card style={styles.cardBody2}><Feed /></Card>
                    </CardGroup>

                    <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Avdelning</th>
                        <th>Namn</th>
                        <th>Telefonnummer</th>
                        <th>E-mail</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Rektor</td>
                        <td>Åsa Frödin</td>
                        
                        <td><a href="tel:+46 18 40 46 30">018 40 46 30</a></td>
                        <td><a href="mailto:rektor@musikladan.se">rektor@musikladan.se</a></td>
                    </tr>
                    <tr>
                        <td>Admin</td>
                        <td>Lindah Keyser</td>
                        <td><a href="tel:+46 18 40 46 86">018 40 46 30</a></td>
                        <td><a href="mailto:lindah.keyser@musikladan.se">lindah.keyser@musikladan.se</a></td>

                    </tr>
                    <tr>
                        <td>Skolsköterska</td>
                        <td>Mehrunnessa Arif</td>
                        <td><a href="tel:+46 18 40 46 36">018 40 46 36</a></td>
                        <td><a href="mailto:mehrunnessa.arif@musikladan.se">mehrunnessa.arif@musikladan.se</a></td>

                    </tr>
                    
                    
                </tbody>
            </Table>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Avdelning</th>
                        
                        <th>Telefonnummer</th>
                        
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Personalrum</td>
                        
                        <td><a href="tel:+46 18 40 46 86">018 40 46 86</a></td>
                    </tr>
                    <tr>
                        <td>Musiklådans Fritidshem</td>
                        
                        <td><a href="tel:+46 18 40 46 93">018 40 46 93</a></td>
                    </tr>
                    <tr>
                        <td>Stora Fritids</td>
                        
                        <td><a href="tel:+46 738484642">0738 48 46 42</a></td>
                    </tr>
                    
                    <tr>
                        <td>Lilla Fritids</td>
                        
                        <td><a href="tel:+46 738384693">0738 48 46 93</a></td>
                    </tr>
                </tbody>
            </Table>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Styrelsen</th>
                        <th>Namn</th>
                        
                        <th>E-mail</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Ordförare</td>
                        <td>Ljiljana Gubeljic</td>
                        
                        
                        <td><a href="mailto:styrelsen@musikladan.se">styrelsen@musikladan.se</a></td>
                    </tr>
                    <tr>
                        <td>Sekreterare</td>
                        <td>Hanna Rosén Schlaug</td>
                        
                        <td><a href="mailto:Hanna.Schlaug@gmail.com">Hanna.Schlaug@gmail.com</a></td>

                    </tr>
                    
                    
                </tbody>
            </Table>


        </div>

        
    );
}

export default Home;